import type {
	SchemaId,
	CmdbObjectTypeId,
	CmdbObjectId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { SCHEMA_PAGE_QUERY_PARAMS } from './services';

// Please try to keep this package very light-weight, since it is used in critical code such as routes.

export {
	useUpdateQueryParams,
	useObjectIdQueryParam,
	useObjectTypeIdQueryParam,
	useSchemaViewModeQueryParam,
	useObjectViewModeQueryParam,
	SCHEMA_PAGE_QUERY_PARAMS,
} from './services';

export type { ObjectViewMode, SchemaViewMode } from './services';

// By convention, routes start with a '/' and do not including a trailing slash.
export const CREATE_SERVICE_URL = '/jira/servicedesk/service-modal';
export const ASSETS_ROUTE_PREFIX = '/jira/servicedesk/assets';
export const INSIGHT_ROUTE_PREFIX = '/jira/servicedesk/insight';

export const isInsightRoute = (pathname: string) => pathname.includes(INSIGHT_ROUTE_PREFIX);

export const getCreateServiceUrl = () => CREATE_SERVICE_URL;

export const getRootPath = () => ASSETS_ROUTE_PREFIX;

export const getLandingPageUrl = () => getRootPath();

export const getGlobalConfigUrl = () => `${getRootPath()}/configure/global`;

export const getDataManagerUrl = () => `${getRootPath()}/data-manager/`;

export const getDataManagerAdaptersUrl = () => `${getRootPath()}/data-manager/adapters/`;

export const getAssetsReportsUrl = () => `${getRootPath()}/reports`;

export const createObjectSchemasUrl = () => `${getRootPath()}/object-schemas`;

export const createObjectSchemaUrl = (objectSchemaId: SchemaId) =>
	`${getRootPath()}/object-schema/${objectSchemaId}`;

export const createObjectTypeUrl = (objectSchemaId: SchemaId, objectTypeId: CmdbObjectTypeId) =>
	`${createObjectSchemaUrl(objectSchemaId)}?${
		SCHEMA_PAGE_QUERY_PARAMS.objectTypeId
	}=${objectTypeId}`;

export const createObjectSchemaConfigUrl = (objectSchemaId: SchemaId) =>
	`${getRootPath()}/configure/object-schema/${objectSchemaId}`;

export const createObjectDetailUrl = (objectId: CmdbObjectId) =>
	`${ASSETS_ROUTE_PREFIX}/object/${objectId}`;

export const createObjectTypeSettingsUrl = (objectTypeId: CmdbObjectTypeId) =>
	`${getRootPath()}/configure/object-type/${objectTypeId}`;

export const createObjectTypeListWithObjectSelectedUrl = (
	objectSchemaId: SchemaId,
	objectTypeId: CmdbObjectTypeId,
	objectId: CmdbObjectId,
) => `${createObjectTypeUrl(objectSchemaId, objectTypeId)}&view=list&objectId=${objectId}`;

export const getFeatureUsagePageUrl = ({
	showChangeLimitModal,
}: {
	showChangeLimitModal?: boolean;
} = {}) =>
	`/jira/settings/products/servicedesk/feature-usage?feature=assets${showChangeLimitModal ? '&changeLimit=true' : ''}`;

export const getFeatureUsageVirtualAgentPageUrl = ({
	showChangeLimitModal,
}: {
	showChangeLimitModal?: boolean;
} = {}) =>
	`/jira/settings/products/servicedesk/feature-usage?feature=virtual-agent${showChangeLimitModal ? '&changeLimit=true' : ''}`;
